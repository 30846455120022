export const isEmpty = (obj): boolean => {
  if (obj === undefined || obj === null) {
    return true;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  if (typeof obj === 'object') {
    return !Object.keys(obj).length;
  }

  return !obj;
};

export const arrayChunk = (items, n: number = 3) => {
  const chunks: Array<typeof items> = [];

  const array = items.slice();
  while (array.length) {
    const chunk = array.splice(0, n);
    chunks.push(chunk);
  }

  return chunks;
};

export const getYoutubeId = (value) => {
  const SRC_REGEX = /src="(.*?)"/im;

  const url = SRC_REGEX.test(value) ? value.match(SRC_REGEX)[1] : value;

  const { searchParams, pathname } = new URL(url);

  return searchParams.get('v') || pathname.replace('/embed', '').replace('/', '');
};

export const filterByTagId = (items, tid: string, limit: number = 0) => {
  let filtered;
  if (tid === '') {
    filtered = items;
  } else {
    filtered = items.filter((item) => item.tags.id === tid);
  }

  return limit === 0 ? filtered : filtered.slice(0, limit);
};

export const convertDate = (date: string) => date.split(' ')[0].split('-').reverse().join('-');

export const stripHTML = (html: string = '<div></div>'): string =>
  html.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '');

export default isEmpty;
