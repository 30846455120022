import React, { FC, useEffect } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import { stripHTML } from 'utils/functions';

import { Article, SearchProps } from './models';

import './Search.scss';

const isEmpty = require('lodash/isEmpty');

interface SearchState {
  keyword: string;
}

const Search: FC<{ data: SearchProps }> = ({
  data: {
    search: { urls, seo, title, image, resultsFound, noResultsFound },
    allArticle: { nodes: items },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  const location = useLocation();
  const state = location.state as SearchState;

  let results: Article[] = [];
  let keyword = '';

  if (state) {
    keyword = state.keyword;
    results = items.filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()));
  }

  useEffect(() => {
    if (isEmpty(keyword)) {
      navigate('/');
    }
  }, [keyword]);

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="search">
        <div className="search__image">
          <Image imageData={image} alt={title} className="w-100" />
          <h2>{title}</h2>
        </div>
        <div className="container section-container mx-auto py-5">
          <div className="row">
            <div className="col">
              {results.length > 0 ? (
                <div className="pb-4">
                  {resultsFound.replace('%t', results.length.toString()).replace('%s', keyword)}
                  <hr />
                </div>
              ) : (
                <h2>{noResultsFound}</h2>
              )}
            </div>
          </div>
          {results.map(({ name, body, url }) => (
            <div className="row py-4" key={url}>
              <div className="col">
                <div className="search__item">
                  <h3>
                    <Link to={url}>{name}</Link>
                  </h3>
                  <div>{stripHTML(body).substring(0, 200)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    search(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      title
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      resultsFound
      noResultsFound
    }
    allArticle {
      nodes {
        url
        name
        body
      }
    }
  }
`;

export default Search;
